import * as i0 from '@angular/core';
import { inject, ElementRef, Component, ChangeDetectionStrategy, Inject, Self, Injectable, Directive, NgModule } from '@angular/core';
import * as i4 from '@taiga-ui/cdk';
import { tuiCreateTokenFromFactory, TUI_IS_MOBILE, tuiIsNumber, TuiDestroyService, AbstractTuiDialogService, TuiIdService, AbstractTuiDialogDirective, tuiAsAlerts } from '@taiga-ui/cdk';
import { tuiFadeIn, tuiSlideIn, tuiHeightCollapse } from '@taiga-ui/core/animations';
import { TUI_ANIMATION_OPTIONS, TUI_NOTIFICATION_OPTIONS } from '@taiga-ui/core/tokens';
import * as i3 from '@tinkoff/ng-polymorpheus';
import { POLYMORPHEUS_CONTEXT, PolymorpheusComponent, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { timer, fromEvent } from 'rxjs';
import { takeUntil, repeatWhen } from 'rxjs/operators';
import * as i1 from '@taiga-ui/core/components/notification';
import { TuiNotificationModule } from '@taiga-ui/core/components/notification';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
function TuiAlertComponent_label_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r3 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r3, " ");
  }
}
function TuiAlertComponent_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 4);
    i0.ɵɵtemplate(1, TuiAlertComponent_label_1_ng_container_1_Template, 2, 1, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r0.item.label)("polymorpheusOutletContext", ctx_r0.item);
  }
}
function TuiAlertComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 6);
  }
  if (rf & 2) {
    const text_r4 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("innerHTML", text_r4, i0.ɵɵsanitizeHtml);
  }
}
const TUI_ALERT_POSITION = tuiCreateTokenFromFactory(() => inject(TUI_IS_MOBILE) ? `1rem 1rem 0 auto` : `2rem 3rem 0 auto`);

// TODO: get rid of $any in template
class TuiAlertComponent {
  constructor(el, destroy$, position, options, item) {
    this.el = el;
    this.destroy$ = destroy$;
    this.position = position;
    this.options = options;
    this.item = item;
    this.autoClose = typeof this.item.autoClose === 'function' ? this.item.autoClose(this.item.status) : this.item.autoClose;
    this.animation = this.position.endsWith('auto') ? Object.assign(Object.assign({}, this.options), {
      value: 'right'
    }) : Object.assign(Object.assign({}, this.options), {
      value: 'left'
    });
  }
  ngOnInit() {
    this.initAutoClose();
  }
  close() {
    this.item.$implicit.complete();
  }
  initAutoClose() {
    if (!this.autoClose) {
      return;
    }
    timer(tuiIsNumber(this.autoClose) ? this.autoClose : 3000).pipe(takeUntil(fromEvent(this.el.nativeElement, 'mouseenter')),
    /**
     * TODO: replace to
     * repeat({
     *    delay: () => fromEvent(this.el.nativeElement, 'mouseleave'),
     * })
     *
     * in RxJS 7
     */
    // eslint-disable-next-line rxjs/no-ignored-notifier
    repeatWhen(() => fromEvent(this.el.nativeElement, 'mouseleave')), takeUntil(this.destroy$)).subscribe(() => this.close());
  }
}
TuiAlertComponent.ɵfac = function TuiAlertComponent_Factory(t) {
  return new (t || TuiAlertComponent)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TUI_ALERT_POSITION), i0.ɵɵdirectiveInject(TUI_ANIMATION_OPTIONS), i0.ɵɵdirectiveInject(POLYMORPHEUS_CONTEXT));
};
TuiAlertComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiAlertComponent,
  selectors: [["tui-alert"]],
  hostAttrs: ["role", "alert"],
  hostVars: 5,
  hostBindings: function TuiAlertComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵsyntheticHostProperty("@tuiFadeIn", ctx.options)("@tuiSlideIn", ctx.animation)("@tuiHeightCollapse", ctx.animation);
      i0.ɵɵstyleProp("margin", ctx.position);
    }
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])],
  decls: 4,
  vars: 7,
  consts: [[3, "hasIcon", "hideClose", "icon", "status", "close"], ["automation-id", "tui-notification-alert__heading", "class", "t-heading", 4, "ngIf"], ["automation-id", "tui-notification-alert__content", 1, "t-content"], [3, "innerHTML", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["automation-id", "tui-notification-alert__heading", 1, "t-heading"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], [3, "innerHTML"]],
  template: function TuiAlertComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "tui-notification", 0);
      i0.ɵɵlistener("close", function TuiAlertComponent_Template_tui_notification_close_0_listener() {
        return ctx.close();
      });
      i0.ɵɵtemplate(1, TuiAlertComponent_label_1_Template, 2, 2, "label", 1);
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵtemplate(3, TuiAlertComponent_div_3_Template, 1, 1, "div", 3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("hasIcon", ctx.item.hasIcon)("hideClose", !ctx.item.hasCloseButton)("icon", ctx.item.icon)("status", ctx.item.status);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.item.label);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("polymorpheusOutlet", ctx.item.content)("polymorpheusOutletContext", ctx.item);
    }
  },
  dependencies: [i1.TuiNotificationComponent, i2.NgIf, i3.PolymorpheusOutletDirective],
  styles: ["[_nghost-%COMP%]{box-shadow:0 1.5rem 1rem #00000008,0 .75rem .75rem #0000000a,0 .25rem .375rem #0000000d;display:block;border-radius:var(--tui-radius-l);width:18rem}[_nghost-%COMP%]:not(:first-child){margin-top:.75rem!important}[_nghost-%COMP%]:not(:last-child){margin-bottom:0!important}.t-heading[_ngcontent-%COMP%]{margin:0}[data-size=s][_ngcontent-%COMP%]   .t-heading[_ngcontent-%COMP%]{font:var(--tui-font-text-s);font-weight:bold}[data-size=m][_ngcontent-%COMP%]   .t-heading[_ngcontent-%COMP%]{font:var(--tui-font-text-m);line-height:1.25rem;font-weight:bold}[data-size=l][_ngcontent-%COMP%]   .t-heading[_ngcontent-%COMP%]{font:var(--tui-font-text-l);line-height:1.5rem;font-weight:bold}.t-content[_ngcontent-%COMP%]{color:var(--tui-text-01);word-wrap:break-word;word-break:break-word}.t-content[_ngcontent-%COMP%]:empty{display:none}"],
  data: {
    animation: [tuiFadeIn, tuiSlideIn, tuiHeightCollapse]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertComponent, [{
    type: Component,
    args: [{
      selector: 'tui-alert',
      templateUrl: './alert.template.html',
      styleUrls: ['./alert.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TuiDestroyService],
      animations: [tuiFadeIn, tuiSlideIn, tuiHeightCollapse],
      host: {
        role: 'alert',
        '[style.margin]': 'position',
        '[@tuiFadeIn]': 'options',
        '[@tuiSlideIn]': 'animation',
        '[@tuiHeightCollapse]': 'animation'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i4.TuiDestroyService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ALERT_POSITION]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ANIMATION_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [POLYMORPHEUS_CONTEXT]
      }]
    }];
  }, null);
})();
class TuiAlertService extends AbstractTuiDialogService {
  constructor(defaultOptions, idService) {
    super(idService);
    this.defaultOptions = defaultOptions;
    this.component = new PolymorpheusComponent(TuiAlertComponent);
  }
}
TuiAlertService.ɵfac = function TuiAlertService_Factory(t) {
  return new (t || TuiAlertService)(i0.ɵɵinject(TUI_NOTIFICATION_OPTIONS), i0.ɵɵinject(TuiIdService));
};
TuiAlertService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiAlertService,
  factory: TuiAlertService.ɵfac,
  providedIn: `root`
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertService, [{
    type: Injectable,
    args: [{
      providedIn: `root`
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_NOTIFICATION_OPTIONS]
      }]
    }, {
      type: i4.TuiIdService,
      decorators: [{
        type: Inject,
        args: [TuiIdService]
      }]
    }];
  }, null);
})();
class TuiAlertDirective extends AbstractTuiDialogDirective {}
TuiAlertDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiAlertDirective_BaseFactory;
  return function TuiAlertDirective_Factory(t) {
    return (ɵTuiAlertDirective_BaseFactory || (ɵTuiAlertDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiAlertDirective)))(t || TuiAlertDirective);
  };
})();
TuiAlertDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiAlertDirective,
  selectors: [["ng-template", "tuiAlert", ""]],
  inputs: {
    options: [i0.ɵɵInputFlags.None, "tuiAlertOptions", "options"],
    open: [i0.ɵɵInputFlags.None, "tuiAlert", "open"]
  },
  outputs: {
    openChange: "tuiAlertChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: AbstractTuiDialogService,
    useExisting: TuiAlertService
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertDirective, [{
    type: Directive,
    args: [{
      selector: 'ng-template[tuiAlert]',
      providers: [{
        provide: AbstractTuiDialogService,
        useExisting: TuiAlertService
      }],
      inputs: ['options: tuiAlertOptions', 'open: tuiAlert'],
      outputs: ['openChange: tuiAlertChange']
    }]
  }], null, null);
})();
class TuiAlertModule {}
TuiAlertModule.ɵfac = function TuiAlertModule_Factory(t) {
  return new (t || TuiAlertModule)();
};
TuiAlertModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiAlertModule
});
TuiAlertModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [tuiAsAlerts(TuiAlertService)],
  imports: [[CommonModule, PolymorpheusModule, TuiNotificationModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiNotificationModule],
      declarations: [TuiAlertComponent, TuiAlertDirective],
      exports: [TuiAlertComponent, TuiAlertDirective],
      providers: [tuiAsAlerts(TuiAlertService)]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ALERT_POSITION, TuiAlertComponent, TuiAlertDirective, TuiAlertModule, TuiAlertService };
