import {
    provideHttpClient,
    withFetch,
    withInterceptors,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {
    provideClientHydration,
    withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    provideRouter,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
} from '@angular/router';
import {responseErrorInterceptors} from '@nx-angular-standalone/core/interceptors';
import {provideLocale} from '@nx-angular-standalone/core/providers';
import {TUI_MEDIA, TuiRootModule} from '@taiga-ui/core';
import {TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE} from '@taiga-ui/i18n';
import {of} from 'rxjs';

import {appRoutes} from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideClientHydration(
            withHttpTransferCacheOptions({
                includePostRequests: true,
                filter: ({url}) => url.includes('api'),
            }),
        ),
        provideRouter(
            appRoutes,
            withComponentInputBinding(),
            withEnabledBlockingInitialNavigation(),
            withInMemoryScrolling({scrollPositionRestoration: 'top'}),
        ),
        importProvidersFrom(BrowserAnimationsModule, TuiRootModule),
        provideHttpClient(
            withInterceptors([responseErrorInterceptors]),
            withInterceptorsFromDi(),
            withFetch(),
        ),
        provideLocale(),
        {
            provide: TUI_LANGUAGE,
            useValue: of(TUI_RUSSIAN_LANGUAGE),
        },
        {
            provide: TUI_MEDIA,
            useValue: {
                mobile: 640,
                desktopSmall: 1024,
                desktopLarge: 1440,
            },
        },
    ],
};
