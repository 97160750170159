import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { ElementRef, Renderer2, Directive, Inject, HostListener, NgModule } from '@angular/core';
import { tuiContainsOrAfter, tuiIsHTMLElement } from '@taiga-ui/cdk/utils/dom';
import { tuiGetNativeFocused, tuiGetClosestFocusable, tuiBlurNativeFocused } from '@taiga-ui/cdk/utils/focus';
class TuiFocusTrapDirective {
  constructor(doc, el, renderer) {
    this.doc = doc;
    this.el = el;
    this.renderer = renderer;
    this.activeElement = tuiGetNativeFocused(this.doc);
    /**
     * This would cause currently focused element to lose focus,
     * but it might cause ExpressionChanged error due to potential HostBinding.
     * Microtask keeps it in the same frame but allows change detection to run
     */
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.resolve().then(() => {
      this.el.nativeElement.focus();
    });
  }
  onBlur() {
    this.renderer.removeAttribute(this.el.nativeElement, 'tabIndex');
  }
  onFocusIn(node) {
    const {
      nativeElement
    } = this.el;
    if (tuiContainsOrAfter(nativeElement, node)) {
      return;
    }
    const focusable = tuiGetClosestFocusable({
      initial: nativeElement,
      root: nativeElement
    });
    if (focusable) {
      focusable.focus();
    }
  }
  ngOnDestroy() {
    tuiBlurNativeFocused(this.doc);
    /**
     * HostListeners are triggered even after ngOnDestroy
     * {@link https://github.com/angular/angular/issues/38100}
     * so we need to delay it but stay in the same sync cycle,
     * therefore using Promise instead of setTimeout
     */
    // eslint-disable-next-line
    Promise.resolve().then(() => {
      if (tuiIsHTMLElement(this.activeElement)) {
        this.activeElement.focus();
      }
    });
  }
}
TuiFocusTrapDirective.ɵfac = function TuiFocusTrapDirective_Factory(t) {
  return new (t || TuiFocusTrapDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(Renderer2));
};
TuiFocusTrapDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiFocusTrapDirective,
  selectors: [["", "tuiFocusTrap", ""]],
  hostAttrs: ["tabIndex", "0"],
  hostBindings: function TuiFocusTrapDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("blur", function TuiFocusTrapDirective_blur_HostBindingHandler() {
        return ctx.onBlur();
      })("focusin.silent", function TuiFocusTrapDirective_focusin_silent_HostBindingHandler($event) {
        return ctx.onFocusIn($event.target);
      }, false, i0.ɵɵresolveWindow);
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusTrapDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiFocusTrap]',
      host: {
        tabIndex: '0'
      }
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.Renderer2,
      decorators: [{
        type: Inject,
        args: [Renderer2]
      }]
    }];
  }, {
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }],
    onFocusIn: [{
      type: HostListener,
      args: ['window:focusin.silent', ['$event.target']]
    }]
  });
})();
class TuiFocusTrapModule {}
TuiFocusTrapModule.ɵfac = function TuiFocusTrapModule_Factory(t) {
  return new (t || TuiFocusTrapModule)();
};
TuiFocusTrapModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFocusTrapModule
});
TuiFocusTrapModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusTrapModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFocusTrapDirective],
      exports: [TuiFocusTrapDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFocusTrapDirective, TuiFocusTrapModule };
