import {inject, Provider} from '@angular/core';
import {ENVIRONMENT} from '@nx-angular-standalone/shared/environments';
import {TuiSafeHtml} from '@taiga-ui/cdk';
import {TUI_SANITIZER, TUI_SVG_SRC_INTERCEPTORS} from '@taiga-ui/core';
import {NgDompurifySanitizer} from '@tinkoff/ng-dompurify';

export const SVG_PROVIDER: Provider[] = [
    {
        provide: TUI_SANITIZER,
        useClass: NgDompurifySanitizer,
    },
    {
        provide: TUI_SVG_SRC_INTERCEPTORS,
        useFactory: () => {
            const {baseUrl} = inject(ENVIRONMENT);
            const url = baseUrl.includes('/backend/api')
                ? baseUrl.replace('/backend/api', '')
                : baseUrl.includes('/api')
                ? baseUrl.replace('/api', '')
                : baseUrl;

            return (src: TuiSafeHtml) =>
                String(src).startsWith('client::')
                    ? `${url}/assets/svg/${String(src).replace('client::', '')}.svg`
                    : src;
        },
        multi: true,
    },
];
