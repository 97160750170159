import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Inject, Input, HostBinding, Output, NgModule } from '@angular/core';
import { tuiIsObserved } from '@taiga-ui/cdk';
import { TUI_CLOSE_WORD, TUI_COMMON_ICONS, TUI_NOTIFICATION_OPTIONS } from '@taiga-ui/core/tokens';
import * as i1 from '@taiga-ui/core/components/svg';
import { TuiSvgModule } from '@taiga-ui/core/components/svg';
import * as i2 from '@taiga-ui/core/components/button';
import { TuiButtonModule } from '@taiga-ui/core/components/button';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i5 from 'rxjs';
function TuiNotificationComponent_ng_container_0_tui_svg_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 4);
  }
  if (rf & 2) {
    const iconName_r3 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("src", iconName_r3);
  }
}
const _c0 = a0 => ({
  $implicit: a0
});
function TuiNotificationComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiNotificationComponent_ng_container_0_tui_svg_1_Template, 1, 1, "tui-svg", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r0.icon)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx_r0.status));
  }
}
function TuiNotificationComponent_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 5);
    i0.ɵɵlistener("click", function TuiNotificationComponent_button_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.close.emit());
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r1.icons.close)("title", i0.ɵɵpipeBind1(1, 2, ctx_r1.closeWord$));
  }
}
const _c1 = ["*"];
class TuiNotificationComponent {
  constructor(closeWord$, icons, options) {
    this.closeWord$ = closeWord$;
    this.icons = icons;
    this.options = options;
    /**
     * @deprecated Use {@link TuiNotificationComponent.icon} input or TUI_NOTIFICATION_OPTIONS instead
     */
    this.hasIcon = this.options.hasIcon;
    this.icon = this.options.icon;
    this.status = this.options.status;
    this.size = this.options.size;
    this.hideClose = false;
    this.close = new EventEmitter();
  }
  get hasClose() {
    return !this.hideClose && tuiIsObserved(this.close);
  }
}
TuiNotificationComponent.ɵfac = function TuiNotificationComponent_Factory(t) {
  return new (t || TuiNotificationComponent)(i0.ɵɵdirectiveInject(TUI_CLOSE_WORD), i0.ɵɵdirectiveInject(TUI_COMMON_ICONS), i0.ɵɵdirectiveInject(TUI_NOTIFICATION_OPTIONS));
};
TuiNotificationComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiNotificationComponent,
  selectors: [["tui-notification"]],
  hostVars: 2,
  hostBindings: function TuiNotificationComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-status", ctx.status)("data-size", ctx.size);
    }
  },
  inputs: {
    hasIcon: "hasIcon",
    icon: "icon",
    status: "status",
    size: "size",
    hideClose: "hideClose"
  },
  outputs: {
    close: "close"
  },
  ngContentSelectors: _c1,
  decls: 4,
  vars: 2,
  consts: [[4, "ngIf"], [1, "t-content"], ["appearance", "icon", "automation-id", "tui-notification__close", "size", "xs", "tuiIconButton", "", "type", "button", "class", "t-close", 3, "icon", "title", "click", 4, "ngIf"], ["automation-id", "tui-notification__icon", "class", "t-icon", 3, "src", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["automation-id", "tui-notification__icon", 1, "t-icon", 3, "src"], ["appearance", "icon", "automation-id", "tui-notification__close", "size", "xs", "tuiIconButton", "", "type", "button", 1, "t-close", 3, "icon", "title", "click"]],
  template: function TuiNotificationComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, TuiNotificationComponent_ng_container_0_Template, 2, 4, "ng-container", 0);
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(3, TuiNotificationComponent_button_3_Template, 2, 4, "button", 2);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.icon && ctx.hasIcon);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.hasClose);
    }
  },
  dependencies: [i1.TuiSvgComponent, i2.TuiButtonComponent, i3.NgIf, i4.PolymorpheusOutletDirective, i3.AsyncPipe],
  styles: ["[_nghost-%COMP%]{font:var(--tui-font-text-s);color:var(--tui-text-01);position:relative;display:flex;padding:.75rem 1rem;border-radius:var(--tui-radius-m);background:#fff;background:var(--tui-base-01);box-sizing:border-box;overflow:hidden}[data-size=s][_nghost-%COMP%]{padding:.375rem .625rem}[data-size=s][_nghost-%COMP%]   .t-icon[_ngcontent-%COMP%]{width:1rem;height:1.25rem;margin:0 .375rem 0 -.125rem}[data-size=s][_nghost-%COMP%]   .t-close[_ngcontent-%COMP%]{margin:-.125rem -.375rem -.125rem .75rem}[data-size=m][_nghost-%COMP%]{padding:.75rem}[data-size=m][_nghost-%COMP%]   .t-icon[_ngcontent-%COMP%]{width:1.25rem;height:1.25rem;margin-right:.5rem}[data-size=m][_nghost-%COMP%]   .t-close[_ngcontent-%COMP%]{margin:-.125rem -.125rem -.125rem 1rem}[data-size=l][_nghost-%COMP%]{padding:1rem;font:var(--tui-font-text-m);border-radius:var(--tui-radius-l)}[data-size=l][_nghost-%COMP%]   .t-icon[_ngcontent-%COMP%]{width:1.5rem;height:1.5rem;margin-right:.5rem}[data-status=info][_nghost-%COMP%]{color:var(--tui-info-fill);background:linear-gradient(var(--tui-info-bg),var(--tui-info-bg)),var(--tui-base-01)}[data-status=success][_nghost-%COMP%]{color:var(--tui-success-fill);background:linear-gradient(var(--tui-success-bg),var(--tui-success-bg)),var(--tui-base-01)}[data-status=error][_nghost-%COMP%]{color:var(--tui-error-fill);background:linear-gradient(var(--tui-error-bg),var(--tui-error-bg)),var(--tui-base-01)}[data-status=warning][_nghost-%COMP%]{color:var(--tui-warning-fill);background:linear-gradient(var(--tui-warning-bg),var(--tui-warning-bg)),var(--tui-base-01)}[data-status=neutral][_nghost-%COMP%]{color:var(--tui-neutral-fill);background:linear-gradient(var(--tui-neutral-bg),var(--tui-neutral-bg)),var(--tui-base-01)}.t-content[_ngcontent-%COMP%]{flex:1;word-wrap:break-word;color:var(--tui-text-01)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNotificationComponent, [{
    type: Component,
    args: [{
      selector: 'tui-notification',
      templateUrl: './notification.template.html',
      styleUrls: ['./notification.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i5.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_CLOSE_WORD]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_COMMON_ICONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_NOTIFICATION_OPTIONS]
      }]
    }];
  }, {
    hasIcon: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    status: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-status']
    }],
    size: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-size']
    }],
    hideClose: [{
      type: Input
    }],
    close: [{
      type: Output
    }]
  });
})();
class TuiNotificationModule {}
TuiNotificationModule.ɵfac = function TuiNotificationModule_Factory(t) {
  return new (t || TuiNotificationModule)();
};
TuiNotificationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiNotificationModule
});
TuiNotificationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiSvgModule, TuiButtonModule, PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNotificationModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiSvgModule, TuiButtonModule, PolymorpheusModule],
      declarations: [TuiNotificationComponent],
      exports: [TuiNotificationComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiNotificationComponent, TuiNotificationModule };
