import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, tap} from 'rxjs';

export const responseErrorInterceptors = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
    const router = inject(Router);

    return next(request).pipe(
        tap({
            error: (error: unknown) => {
                if (!(error instanceof HttpErrorResponse)) {
                    return;
                }

                const {status, url} = error;
                const urlModalBanner = 'modal-banner';
                const newsDetailsUrl = url?.includes('details') && url?.includes('news');
                const matchesByTournamentUrl =
                    url?.includes('matches') && url?.includes('tournament');
                const matchesDetailsUrl =
                    url?.includes('matches') && url?.includes('detail');
                const urlPath = url?.split('/').splice(-2, 1).join('');

                switch (status) {
                    case 404:
                        if (newsDetailsUrl) {
                            void router.navigateByUrl('/news');
                            break;
                        }

                        if (matchesByTournamentUrl || matchesDetailsUrl) {
                            void router.navigateByUrl('/match-center');
                            break;
                        }

                        if (urlPath !== urlModalBanner) {
                            void router.navigateByUrl('/not-found');
                        }

                        break;
                    case 500:
                        void router.navigateByUrl('/engineering-works');
                        break;
                }
            },
        }),
    );
};
