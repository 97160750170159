import {bootstrapApplication} from '@angular/platform-browser';
import {addGoogleAnalytic, addYandexMetrica} from '@nx-angular-standalone/shared/util';

import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';

addYandexMetrica();
addGoogleAnalytic();

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
