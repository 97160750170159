import * as i0 from '@angular/core';
import { Injector, INJECTOR, ChangeDetectorRef, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Self, NgModule } from '@angular/core';
import { TUI_PARENT_ANIMATION } from '@taiga-ui/cdk/constants';
import { TuiDestroyService } from '@taiga-ui/cdk/services';
import { TUI_ALERTS } from '@taiga-ui/cdk/tokens';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import * as i3 from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@taiga-ui/cdk/pipes';
import { TuiMapperPipeModule } from '@taiga-ui/cdk/pipes';
function TuiAlertHostComponent_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 3);
    i0.ɵɵpipe(1, "tuiMapper");
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngComponentOutlet", item_r3.component.component)("ngComponentOutletInjector", i0.ɵɵpipeBind2(1, 2, item_r3, ctx_r2.mapper));
  }
}
function TuiAlertHostComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, TuiAlertHostComponent_div_0_ng_container_1_Template, 2, 5, "ng-container", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const alert_r1 = ctx.$implicit;
    i0.ɵɵproperty("@tuiParentAnimation", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", alert_r1);
  }
}
class TuiAlertHostComponent {
  constructor(allAlerts, injector, destroy$, cdr) {
    this.allAlerts = allAlerts;
    this.injector = injector;
    this.destroy$ = destroy$;
    this.cdr = cdr;
    this.alerts = [];
    this.trackBy = index => index;
    this.mapper = useValue => Injector.create({
      providers: [{
        provide: POLYMORPHEUS_CONTEXT,
        useValue
      }],
      parent: this.injector
    });
  }
  ngOnInit() {
    // Due to this view being parallel to app content, `markForCheck` from `async` pipe
    // can happen after view was checked, so calling `detectChanges` instead
    combineLatest(this.allAlerts).pipe(takeUntil(this.destroy$)).subscribe(alerts => {
      this.alerts = alerts;
      this.cdr.detectChanges();
    });
  }
}
TuiAlertHostComponent.ɵfac = function TuiAlertHostComponent_Factory(t) {
  return new (t || TuiAlertHostComponent)(i0.ɵɵdirectiveInject(TUI_ALERTS), i0.ɵɵdirectiveInject(INJECTOR), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(ChangeDetectorRef));
};
TuiAlertHostComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiAlertHostComponent,
  selectors: [["tui-alert-host"]],
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])],
  decls: 1,
  vars: 2,
  consts: [["class", "t-wrapper", 4, "ngFor", "ngForOf", "ngForTrackBy"], [1, "t-wrapper"], [3, "ngComponentOutlet", "ngComponentOutletInjector", 4, "ngFor", "ngForOf"], [3, "ngComponentOutlet", "ngComponentOutletInjector"]],
  template: function TuiAlertHostComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiAlertHostComponent_div_0_Template, 2, 2, "div", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", ctx.alerts)("ngForTrackBy", ctx.trackBy);
    }
  },
  dependencies: [i1.NgForOf, i1.NgComponentOutlet, i2.TuiMapperPipe],
  styles: ["tui-alert-host>.t-wrapper{position:fixed;top:0;left:0;display:flex;width:100%;height:100%;flex-direction:column;pointer-events:none}tui-alert-host>.t-wrapper>*{pointer-events:auto}\n"],
  encapsulation: 2,
  data: {
    animation: [TUI_PARENT_ANIMATION]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertHostComponent, [{
    type: Component,
    args: [{
      selector: 'tui-alert-host',
      templateUrl: './alert-host.template.html',
      styleUrls: ['./alert-host.style.less'],
      // So that we do not force OnPush on custom alerts
      // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
      changeDetection: ChangeDetectionStrategy.Default,
      providers: [TuiDestroyService],
      animations: [TUI_PARENT_ANIMATION],
      encapsulation: ViewEncapsulation.None
    }]
  }], function () {
    return [{
      type: Array,
      decorators: [{
        type: Inject,
        args: [TUI_ALERTS]
      }]
    }, {
      type: i0.Injector,
      decorators: [{
        type: Inject,
        args: [INJECTOR]
      }]
    }, {
      type: i3.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }];
  }, null);
})();
class TuiAlertHostModule {}
TuiAlertHostModule.ɵfac = function TuiAlertHostModule_Factory(t) {
  return new (t || TuiAlertHostModule)();
};
TuiAlertHostModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiAlertHostModule
});
TuiAlertHostModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiMapperPipeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAlertHostModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiMapperPipeModule],
      declarations: [TuiAlertHostComponent],
      exports: [TuiAlertHostComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiAlertHostComponent, TuiAlertHostModule };
