import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SVG_PROVIDER} from '@nx-angular-standalone/core/providers';
import {MetaService} from '@nx-angular-standalone/shared/data-access';
import {
    createFaqJsonLd,
    defaultFaqs,
    FAQ_JSON_LD_ID,
    WEBSITE_JSON_LD,
    WEBSITE_JSON_LD_ID,
} from '@nx-angular-standalone/shared/util';
import {
    TUI_SANITIZER,
    TuiAlertModule,
    TuiDialogModule,
    TuiRootModule,
} from '@taiga-ui/core';
import {NgDompurifySanitizer} from '@tinkoff/ng-dompurify';

@Component({
    standalone: true,
    selector: 'nx-angular-standalone-root',
    imports: [RouterModule, TuiRootModule, TuiDialogModule, TuiAlertModule],
    template: `
        <tui-root>
            <router-outlet></router-outlet>
        </tui-root>
    `,
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{provide: TUI_SANITIZER, useClass: NgDompurifySanitizer}, SVG_PROVIDER],
})
export class AppComponent {
    constructor() {
        const metaService = inject(MetaService);

        metaService.jsonLD(WEBSITE_JSON_LD, WEBSITE_JSON_LD_ID);
        metaService.jsonLD(createFaqJsonLd(defaultFaqs), FAQ_JSON_LD_ID);
    }
}
