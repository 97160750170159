import {Routes} from '@angular/router';

export const appRoutes: Routes = [
    {
        path: 'engineering-works',
        loadComponent: async () =>
            import('@nx-angular-standalone/engineering-works/feature'),
    },
    {
        path: '',
        loadChildren: async () => import('@nx-angular-standalone/main/feature'),
    },
    {
        path: '**',
        redirectTo: '',
    },
];
